<section class="py-5 bg-white shadow-lg about-area" id="care">
   <div class="container pt-5">
      <div class="row align-items-center justify-content-between">
         <div class="col-12 col-md-12 col-lg-7 order-lg-1 mt-lg-0 mt-md-4 mb-4">
            <div class="large-title">
               <h1 class="h2 mb-3 fontLobster text-capitalize theme-title" #viaCustomer>Care and Cleaning</h1>
            </div>
            <p class="mb-2">
               Porcelain stone is a popular stone choice among the architects, interior designers, and homeowners for various reasons. And one of the most common reasons is its ease of care and maintenance. 
            </p>
            <p class="m-0">
               With proper cleaning and maintenance, the porcelain stone tiles and slabs can be made to look brand new and last a lifetime. However, in the case of poor building practice, incorrect fixing, or extreme weather conditions, our experts can advise on how can the effects be reduced and about the product-specific warranty period.
            </p>
         </div>
         <div class="img-shine col-12 col-md-12 col-lg-5 order-lg-2 text-right">
            <owl-carousel-o [options]="customOptions" class="homeS">
               <ng-container>
                  <ng-template carouselSlide>
                     <div class="show-off">
                        <img src="{{tinyUrl}}/inspiration/1.png" class="m-auto w-auto img-fluid">
                     </div>
                  </ng-template>
               </ng-container>
               <ng-container>
                  <ng-template carouselSlide>
                     <div class="show-off">
                        <img src="{{tinyUrl}}/inspiration/2.png" class="m-auto w-auto img-fluid">
                     </div>
                  </ng-template>
               </ng-container>
               <ng-container>
                  <ng-template carouselSlide>
                     <div class="show-off">
                        <img src="{{tinyUrl}}/inspiration/3.png" class="m-auto w-auto img-fluid">
                     </div>
                  </ng-template>
               </ng-container>
            </owl-carousel-o>
         </div>
      </div>
      <div class="row mt-5">
         <div class="col-xl-6 mb-3">
            <div class="h-100 card mb-3 shadow-sm border-0">
               <div class="card-header border-0 p-0">
                  <h6 class="mb-0 P-4">
                     <div class="p-3 text-left text-dark w-100">How Cleaning Should be Done</div>
                  </h6>
               </div>
               <div class="collapse show">
                  <div class="card-body pb-0 pt-2">
                     <p class="mb-0">Cleaning stone surfaces with mild liquid dishwashing detergent and water keep the stone’s beauty intact for a long time. It is recommended to use a clean rag mop for the best results. To remove soap scum, use a solution of ammonia and water.</p>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-xl-6 mb-3">
            <div class="h-100 card mb-3 shadow-sm border-0">
               <div class="card-header border-0 p-0">
                  <h6 class="mb-0 P-4">
                     <div class="p-3 text-left text-dark w-100"> Cleaning Products</div>
                  </h6>
               </div>
               <div class="collapse show">
                  <div class="card-body pb-0 pt-2">
                     <p class="mb-0">Products containing vinegar or lemon might dull the stone’s beauty. Make sure that you do not mix bleach and ammonia, as this creates harmful gases. Scouring creams contain abrasives, which might scratch stones.
                     </p>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-xl-6 mb-3">
            <div class="h-100 card mb-3 shadow-sm border-0">
               <div class="card-header border-0 p-0">
                  <h6 class="mb-0 P-4">
                     <div class="p-3 text-left text-dark w-100">Sealing the Stone
                     </div>
                  </h6>
               </div>
               <div class="collapse show">
                  <div class="card-body pb-0 pt-2">
                     <p class="mb-0">Sealing products are impregnators, which do not act as a sealant but more like a repellant. Sealing makes the stone more resistant to stain. Talk with the experts about sealing procedures, and you might know the best sealing practices. Consult with the specific sealing manufacturer and the frequency to use.
                     </p>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-xl-6 mb-3">
            <div class="h-100 card mb-3 shadow-sm border-0">
               <div class="card-header border-0 p-0">
                  <h6 class="mb-0 P-4">
                     <div class="p-3 text-left text-dark w-100">Finding Stains and Removing Them
                     </div>
                  </h6>
               </div>
               <div class="collapse show">
                  <div class="card-body pb-0 pt-2">
                     <p class="mb-0">After identifying stains, it becomes easier to remove them. Stains can be acidic, metallic, or paint based. If you are still wondering what caused the stain, then do check out for staining agents that are present. Consider asking some questions like - what is its shape or what color is it.
                     </p>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</section>