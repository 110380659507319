import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { UserService } from '../shared/user.service';
import { CustomerServiceComponent } from '../customer-service/customer-service.component'
declare let $: any;
declare let jQuery: any;
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
	tinyUrl = 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/finowebsite';
	colorArray = []
	newArrArray = []
	autoSuggestionArray = []
	itemTemplate = ''
	productName1
	pNotFound = 'No Product found'
	@ViewChild(CustomerServiceComponent) mycust
	constructor(private UserService: UserService, private router: Router) { }
	ngOnInit(): void {

		window.onscroll = function () { myFunction() };

		var header = document.getElementById("headComp");

		if (header) {
			var sticky = header.offsetTop;

		}


		function myFunction() {
			if (window.pageYOffset > sticky) {
				header.classList.add("sticky");
			} else {
				header.classList.remove("sticky");
			}
		}
		this.getList()
		if ($(window).width() >= 992) {
			$('.headComp .dropdown').click(function () {
				let ele = $(this).find(".dropLarge")
				$(this).find(".dropLarge").css('display', 'none');

			});
			$('.headComp .dropdown').mouseleave(function () {
				$(this).find(".dropLarge").css('display', 'none');

			});
			$('.headComp .dropdown').mouseover(function () {
				$(this).find(".dropLarge").css('display', 'block');
			});
		} else if ($(window).width() <= 991) {
			$('.headComp li a').click(function () {
				$(".navbar-collapse").collapse('hide');
				$(".dropLarge").hide('add');
				$(this).find(".dropdown-menu").css('display', 'none');
			});
			$('.mob-toggle').click(function () {
				$(".dropLarge").removeClass('add');
				$(this).parent(".mob-droupdown").find(".dropLarge").toggle('add');
			});
		}
		// $(window).click(function() {
		// 	//Hide the menus if visible
		// 	// alert('abcd')
		// 	$('#colspNav').removeClass('show')
		//   });
		  

	}


	mobileToggle() {
		if ($(window).width() <= 991) {
			$(".navbar-collapse").collapse('hide');
			$(".dropLarge").hide('add');
			$(this).find(".dropdown-menu").css('display', 'none');
			// $($(this).attr("data-target")).modal("hide");
			// colspNav
			// $('#colspNav').removeClass('show')
		}
	}

	getList() {
		this.UserService.getAllFilter().subscribe(data => {
			// //console.log(data);
			// var myArray:any
			// myArray =  data.product.filter((v,i,a)=>a.findIndex(t=>(t.ProductColor === v.ProductColor))===i)
			// //console.log(myArray);

			// this.colorArray = myArray.filter((a) =>!a.ProductColor.startsWith("#"));
			// let objIndex  = this.colorArray.findIndex((obj => obj.ProductColor == 'Cream'));
			// this.colorArray[objIndex].ProductColorName = "#FFFDD0"
			this.colorArray = data.filter.color


			// //console.log(this.colorArray); 


		})
	}
	getColor(vlr) {
		localStorage.setItem('getColor', JSON.stringify(vlr))
		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
			this.router.navigate(['/porcelain']));
	}
	getNewArrival() {
		this.UserService.getNewArrivalProduct().subscribe(data => {
			this.newArrArray = data.product
		})
	}
	onChangeSearch(val: string) {
		
		if (val) {
			this.autoSuggestionArray = []
			var isNew = 0
			this.UserService.autoSuggestspecialProducts(val, isNew).subscribe(data => {
				if (data.productsuggestion) {
					this.autoSuggestionArray = data.productsuggestion
				}
				return
			})
		}
	}

	selectEvent(data, ngauto) {
		this.productName1 = ""
		this.itemTemplate = ""

		this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
			this.router.navigate(['/porcelain/', data.productSlug]));
			ngauto.clear();
	}
	gotoSearch() {
		// console.log(this.productName1);
		
		var searching: any
		if (this.productName1) {
			this.UserService.autoSuggestspecialProducts(this.productName1, 0).subscribe(data => {
				searching = data.productsuggestion.filter(x => x.ProductName.toLowerCase() == this.productName1.toLowerCase())
				// console.log(searching)
				this.productName1 = ""
				$('.ng-valid').val('')
				return
			})
		}
	}
	ngOnDestroy(){
		this.productName1 = ""
		this.itemTemplate = ""
	}


}
