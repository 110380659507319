import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {InterceptorAuthService} from './interceptor-service/interceptor-auth.service'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
// import * as $ from "jquery";  
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AutocompleteLibModule,
    FormsModule, ReactiveFormsModule
  ],
  providers: [{provide:HTTP_INTERCEPTORS, useClass: InterceptorAuthService, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
