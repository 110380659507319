<header class="headComp" id="headComp">
	<nav class="p-0 navbar navbar-expand-lg navbar-dark mx-background-top-linear">
		<div class="container-fluid pl-0">
			<a class="navbar-brand p-0 fLogo" routerLink="/"><img src="{{tinyUrl}}/logo.png"
					class="img-fluid"></a>
			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#colspNav" (click)="mobileToggle()">
				<span class="navbar-toggler-icon"></span>
			</button>
			<div class="navbar-collapse collapse" id="colspNav">
				<ul class="navbar-nav ml-auto">
					<li class="nav-item dropdown mob-droupdown">
						<a class="py-3 nav-link px-1 px-lg-2 text-white arrow-toggle" (click)="mobileToggle()" routerLink="/porcelain">Product 
						</a>
						
						<button class="mob-toggle btn btn-link px-4"><i aria-hidden="true" class="fa fa-caret-down text-white"></i></button>
						<div class="dropdown-menu dropLarge animate fade-up">
							<div class="d-flex">
								<div class="min-col col-md-4 col-6 px-0">
									<h6 class="title">Color</h6>
									<ul class="list-unstyled nav mt-3">
										<li style="cursor: pointer;" class="text-center mb-2 mr-2">
											<a (click)="mobileToggle();getColor(null)">
												<div class="cardfixnew border mr-0" width="20px" height="20px"
													[ngStyle]="{'background-color': 'white'}"
													routerLink="/porcelain">
												</div>
												<span style="font-size: 13px;">All</span>
											</a>
										</li>
										<li *ngFor="let list of colorArray" (click)="getColor(list)"
											style="cursor: pointer;" class="text-center mb-2 mr-2">
											<a (click)="mobileToggle()">
												<!-- <img src="{{tinyUrl}}/newColor/1.png" width="40px" height="40"> -->
												<div class="cardfixnew border mr-0" width="20px" height="20px"
													[ngStyle]="{'background-color': list.color}">
												</div>
												<span style="font-size: 13px;">{{list.color}}</span>
											</a>
										</li>

									</ul>
								</div>
								<!-- end col-3 -->
								<!-- <div class="min-col col-md-4 col-6">
									<h6 class="title">New Arrival</h6>
									<ul class="list-unstyled">
										<li><a href="#" class="text-dark">Submenu item</a></li>
										<li><a href="#" class="text-dark">Submenu item</a></li>
										<li><a href="#" class="text-dark">Submenu item</a></li>
										<li><a href="#" class="text-dark">Submenu item</a></li>
										<li><a href="#" class="text-dark">Submenu item</a></li>
										<li><a href="#" class="text-dark">Submenu item</a></li>
									</ul>
								</div> -->
								<!-- end col-3 -->
								<!-- <div class="min-col col-md-4 col-6">
									<h6 class="title">Clearance Sale</h6>
									<ul class="list-unstyled">
										<li><a href="#" class="text-dark">Submenu item</a></li>
										<li><a href="#" class="text-dark">Submenu item</a></li>
										<li><a href="#" class="text-dark">Submenu item</a></li>
										<li><a href="#" class="text-dark">Submenu item</a></li>
										<li><a href="#" class="text-dark">Submenu item</a></li>
										<li><a href="#" class="text-dark">Submenu item</a></li>
									</ul>
								</div> -->
								<!-- end col-3 -->
							</div>
							<!-- end row -->
						</div>
						<!-- dropLarge.// -->
					</li>
					
					<li class="nav-item dropdown mob-droupdown">
						<a class="py-3 nav-link px-1 px-lg-2 text-white arrow-toggle" (click)="mobileToggle()" routerLink = "/inspiration">Inspiration</a>
						<button class="mob-toggle btn btn-link px-4"><i aria-hidden="true" class="fa fa-caret-down text-white"></i></button>
						<div class="dropdown-menu dropLarge animate fade-up">
							<div class="d-flex">
								<div class="min-col col-md-6 col-6 px-2">
									<h6 (click)="mobileToggle()" class="title" routerLink = "/residential" style="cursor: pointer;">Residential</h6>
									<div class="row mx-0">
										<div (click)="mobileToggle()" class="col-md-12 card mb-3 px-0" [routerLink]="['/inspiration']"
											[fragment]="'Kitchen'">
											<div class="row no-gutters">
												<div class="col-md-12 d-flex align-items-center">
													<div class="cardfix1">
														<img src="{{tinyUrl}}/newColor/1.png">
													</div>
													<a>Kitchen</a>
												</div>
											</div>
										</div>
										<div class="col-md-12 card mb-3 px-0"  (click)="mobileToggle()" [routerLink]="['/inspiration']"
											[fragment]="'Bathroom'">
											<div class="row no-gutters">
												<div class="col-md-12 d-flex align-items-center">
													<div class="cardfix1">
														<img src="{{tinyUrl}}/newColor/2.png">
													</div>
													<a>Bathroom</a>
												</div>
											</div>
										</div>
										<div class="col-md-12 card mb-3 px-0">
											<div class="row no-gutters"  (click)="mobileToggle()" [routerLink]="['/inspiration']"
												[fragment]="'Living-Space'">
												<div class="col-md-12 d-flex align-items-center">
													<div class="cardfix1">
														<img src="{{tinyUrl}}/newColor/1.png">
													</div>
													<a>Living Space</a>
												</div>
											</div>
										</div>
										<div class="col-md-12 card mb-3 px-0"  (click)="mobileToggle()" [routerLink]="['/inspiration']"
											[fragment]="'Bedroom'">
											<div class="row no-gutters">
												<div class="col-md-12 d-flex align-items-center">
													<div class="cardfix1">
														<img src="{{tinyUrl}}/newColor/2.png">
													</div>
													<a>Bedroom</a>
												</div>
											</div>
										</div>
										<div class="col-md-12 card mb-3 px-0"  (click)="mobileToggle()" [routerLink]="['/inspiration']"
											[fragment]="'Laundry-Room'">
											<div class="row no-gutters">
												<div class="col-md-12 d-flex align-items-center">
													<div class="cardfix1">
														<img src="{{tinyUrl}}/newColor/1.png">
													</div>
													
													<a>Laundry Room</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- end col-3 -->
								<div class="min-col col-md-6 col-6 px-2">
									<h6 (click)="mobileToggle()" class="title" routerLink = "/commercial" style="cursor: pointer;">Commercial</h6>
									<div class="row mx-0">
										<div class="col-md-12 card mb-3 px-0"  (click)="mobileToggle()" [routerLink]="['/inspiration']"
											[fragment]="'Reception'">
											<div class="row no-gutters">
												<div class="col-md-12 d-flex align-items-center">
													<div class="cardfix1">
														<img src="{{tinyUrl}}/newColor/1.png">
													</div>
													<a>Reception</a>
												</div>
											</div>
										</div>
										<div class="col-md-12 card mb-3 px-0"  (click)="mobileToggle()" [routerLink]="['/inspiration']"
											[fragment]="'Lobby'">
											<div class="row no-gutters">
												<div class="col-md-12 d-flex align-items-center">
													<div class="cardfix1">
														<img src="{{tinyUrl}}/newColor/2.png">
													</div>
													<a>Lobby</a>
												</div>
											</div>
										</div>
										<div class="col-md-12 card mb-3 px-0"  (click)="mobileToggle()" [routerLink]="['/inspiration']"
											[fragment]="'Waiting-Area'">
											<div class="row no-gutters">
												<div class="col-md-12 d-flex align-items-center">
													<div class="cardfix1">
														<img src="{{tinyUrl}}/newColor/1.png">
													</div>
													<a>Waiting Area</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- end col-3 -->
							</div>
							<!-- end row -->
						</div>
						<!-- dropLarge.// -->
					</li>
					<li class="nav-item dropdown mob-droupdown">
						<a class="py-3 nav-link px-1 px-lg-2 text-white arrow-toggle" (click)="mobileToggle()" routerLink = "/customer-service">Customer Service</a>
						<button class="mob-toggle btn btn-link px-4"><i aria-hidden="true" class="fa fa-caret-down text-white"></i></button>
						<div class="dropdown-menu dropLarge animate fade-up">
							<div class="col-12 px-0 d-flex">
								<div class="min-col col-md-6 col-6 mb-2 px-2">
									<a class="text-dark card py-2 align-items-center" routerLink="/location">
										<div class="mb-2 text-center">
											<i class="fa fa-map-pin fa-2x"></i>
										</div>
										Location
									</a>
								</div>
								<div class="min-col col-md-6 col-6 mb-2 px-2">
									<a class="text-dark card py-2 align-items-center" routerLink="/contact-us">
										<div class="mb-2 text-center">
											<i class="fa fa-phone fa-2x"></i>
										</div>
										Contact
									</a>
								</div>
							</div>
							<div class="col-12 px-0 d-flex">
								<div class="min-col col-md-6 col-6 mb-2 px-2">
									<a class="text-dark card py-2 align-items-center" routerLink="/customer-service">
										<div class="mb-2 text-center">
											<i class="fa fa-headphones fa-2x"></i>
										</div>
										Care And Cleaning
									</a>
								</div>
								<div class="min-col col-md-6 col-6 mb-2 px-2">
									<a class="text-dark card py-2 align-items-center" routerLink="/faq">
										<div class="mb-2 text-center">
											<i class="fa fa-question fa-2x"></i>
										</div>
										FAQ
									</a>
								</div>
							</div>
							<!-- <div class="col-12 px-0 d-flex">
								<div class="min-col col-md-6 col-6 mb-2 px-2">
									<a class="text-dark card py-2 align-items-center" routerLink="/news">
										<div class="mb-2 text-center">
											<i class="fa fa-newspaper-o fa-2x"></i>
										</div>
										Notice/News
									</a>
								</div>
							</div> -->
							<div class="col-12 px-0 d-flex flex-wrap flex-md-nowrap">
							   <div class="min-col col-md-12 col-sm-12 mb-2 px-2">
							      <a class="text-dark card py-2 align-items-center text-center border mb-2 mb-sm-0" routerLink="/book-appointment">
							         <div class="mb-1 text-center"><i class="fa fa-newspaper-o fa-2x text-dark"></i></div>
							         <div href="javascript:void(0)" class="fontSt1 text-dark">Book An Appointment </div>
							      </a>
							   </div>
							</div>
							<!-- end row -->
						</div>
						<!-- dropLarge.// -->
					</li>
					<li class="nav-item dropdown mob-droupdown">
						<a class="py-3 nav-link px-1 px-lg-2 text-white arrow-toggle" (click)="mobileToggle()" routerLink = "/company">Company</a>
						<button class="mob-toggle btn btn-link px-4"><i aria-hidden="true" class="fa fa-caret-down text-white"></i></button>
						<div class="dropdown-menu dropLarge animate fade-up">
							<div class="col-12 px-0 d-flex">
								<div class="min-col col-md-6 col-6 mb-2 px-2">
									<a class="text-dark card py-2 align-items-center" routerLink="/news">
										<div class="mb-2 text-center">
											<i class="fa fa-newspaper-o fa-2x" aria-hidden="true"></i>
										</div>
										News and Events
									</a>
								</div>
								<div class="min-col col-md-6 col-6 mb-2 px-2">
									<a class="text-dark card py-2 align-items-center" routerLink="/ourstory">
										<div class="mb-2 text-center">
											<i class="fa fa-book fa-2x"></i>
										</div>
										Our Story
									</a>
								</div>
							</div>
							<div class="col-12 px-0 d-flex">
								<div class="min-col col-md-6 col-6 mb-2 px-2">
									<a class="text-dark card py-2 align-items-center" routerLink="/experience">
										<div class="mb-2 text-center">
											<i class="fa fa-headphones fa-2x"></i>
										</div>
										Experience
									</a>
								</div>
								<div class="min-col col-md-6 col-6 mb-2 px-2">
									<a class="text-dark card py-2 align-items-center" routerLink="/career">
										<div class="mb-2 text-center">
											<i class="fa fa-question fa-2x"></i>
										</div>
										Career
									</a>
								</div>
							</div>
							<!-- <div class="col-12 px-0 d-flex">
								<div class="min-col col-md-12 col-12 mb-3 px-2">
									<div class="text-dark card py-2 align-items-center">
										<div class="mb-2 text-center" routerLink="/professional">
											<i class="fa fa-newspaper-o fa-2x"></i>
										</div>
										<a class="fontSt1 text-dark" routerLink="/professional">Profiessionals</a>
										<div class="mt-4 col-12 px-0 d-flex">
											<div class="col-md-6 mb-2">
												<a routerLink="/architect"
													class="text-dark height-100 card p-3 text-center align-items-center justify-content-center">
													<div class="row no-gutters">
														<div class="fontSt1 col-md-12 d-flex align-items-center">
															Architect
														</div>
													</div>
												</a>
											</div>
											<div class="col-md-6 mb-2">
												<a routerLink="/designer"
													class="text-dark height-100 card p-3 text-center align-items-center justify-content-center">
													<div class="row no-gutters">
														<div class="fontSt1 col-md-12 d-flex align-items-center">
															Designer
														</div>
													</div>
												</a>
											</div>
										</div>
										<div class="col-12 px-0 d-flex">
											<div class="col-md-6 mb-2">
												<a routerLink="/kitchen-studios"
													class="text-dark height-100 card p-3 text-center align-items-center justify-content-center">
													<div class="row no-gutters">
														<div class="fontSt1 col-md-12 d-flex align-items-center">
															Kitchen and Bath Studios
														</div>
													</div>
												</a>
											</div>
											<div class="col-md-6 mb-2">
												<a routerLink="/fabricators"
													class="text-dark height-100 card p-3 text-center align-items-center justify-content-center">
													<div class="row no-gutters">
														<div class="fontSt1 col-md-12 d-flex align-items-center">
															Fabricators
														</div>
													</div>
												</a>
											</div>
										</div>
									</div>
								</div>
							</div> -->
							
						</div>
						<!-- dropLarge.// -->
					</li>
				</ul>
			</div>
			<ul class="ml-auto sNet nav">
				<li>
					<a class="waves-effect waves-dark facebook" href="https://www.facebook.com/Fino-Porcelain-105707564811479" target="_blank">
						<i class="fa fa-facebook"></i>
					</a>
				</li>
				<li>
					<a class="waves-effect waves-dark twitter" href="https://twitter.com/FinoPorcelain" target="_blank">
						<i class="fa fa-twitter"></i>
					</a>
				</li>
				<li>
					<a class="waves-effect waves-dark instagram" href="https://www.instagram.com/finoporcelain/" target="_blank">
						<i class="fa fa-instagram"></i>
					</a>
				</li>
			</ul>
		</div>
	</nav>
	
	<div class="searchbar">
		<!-- <input class="search_input" type="text" name="" placeholder="Search your Favorite slabs...">
		<a routerLink = "/" class="search_icon"><i class="fa fa-search"></i></a> -->
		<div class="finder__inner">
			<ng-autocomplete #ngauto [data]="autoSuggestionArray" [searchKeyword]="'ProductName'" class="finder__input"
				(selected)='selectEvent($event, ngauto)' (inputChanged)='onChangeSearch($event)' debounceTime="400"
				[itemTemplate]="itemTemplate" placeholder="Enter the Product Name" [(ngModel)] = "productName1"
				[notFoundTemplate]="notFoundTemplate"  id="nnnnnnnnn">
			</ng-autocomplete>
			<!-- <ng-template #itemTemplate let-item >
				<a [innerHTML]="item?.ProductName" id="abcd"></a>
			</ng-template> -->
			<ng-template #itemTemplate let-item>
				<a [innerHTML]="item?.ProductName" id="abcd"></a>
				</ng-template>
				
				<ng-template #notFoundTemplate let-notFound>
				<div [innerHTML]="pNotFound"></div>
				</ng-template>
			<!-- <div class="finder__icon" ref="icon"></div> -->
			<a  class="search_icon" (click) = "gotoSearch()"><i class="fa fa-search"></i></a>
		</div>
	</div>
</header>