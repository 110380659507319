import { Component, ElementRef, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-customer-service',
  templateUrl: './customer-service.component.html',
  styleUrls: ['./customer-service.component.css']
})
export class CustomerServiceComponent implements OnInit {
  tinyUrl= 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/finowebsite';
  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    margin: 0,
    // animateOut: 'fadeOut',
    // animateIn: 'fadeIn',
    autoplayHoverPause: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    items: 1,
    nav: false
  }
  chieldDaa= "he;;p"
  constructor(private meta:Meta) { }

  ngOnInit(): void {
    this.meta.addTags([
			{ name: 'title', content: 'Proactive Customer Service | Fino Porcelain' },
			{ name: 'description', content: 'We have a team of customer service experts to guide you 24*7 in selecting the best porcelain products for your space. Contact our experts via call or email.' },
		])
  }
}
