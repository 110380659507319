<footer class="py-4 section fClassic context-dark shadow">
	<div class="container">
		<div class="row align-items-center">
			<div class="mb-3 mb-md-0 col-md-8">
				<a class="brand" routerLink="/"><img class="mx-0 brand-logo-light" src="{{tinyUrl}}/logo.png" alt="brand logo"
						width="200"></a>
			</div>
			<div class="mb-3 mb-md-0 col-md-4">
				<!-- <h6>GET THE FINO APP</h6> -->
				<ul class="nav-list footer-logo">
					<li class="bg-transparent px-0 text-left row">
						
						<a href="https://apps.apple.com/us/app/igm-surfaces/id1511126501" target="_black"
							class="text-left col-6 px-2"><img class="mx-md-2 brand-logo-light img-fluid"
								src="{{tinyUrl}}/ios.png" alt="appstore logo">
						</a>
						<a href="https://play.google.com/store/apps/details?id=com.igmcorp" target="_black"
							class="text-left col-6 px-2 mb-3 mb-md-0"><img class="mx-md-2 brand-logo-light img-fluid"
								src="{{tinyUrl}}/playstore.png" alt="playstore logo">
						</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="mb-3 mb-md-0 col-md-8">
				<div class="row">
					<div class="col-md-12 col-xl-12">
						<!-- <h6>Helpful Links</h6> -->
						<ul class="nav-list">
							<li><a  class="text-uppercase" routerLink = "/">Home Page
								</a>
							</li>
							<li><a  class="text-uppercase" routerLink = "/porcelain">PRODUCT
								</a>
							</li>
							<li><a  class="text-uppercase" routerLink = "/inspiration">INSPIRATION
								</a>
							</li>
							<li><a  class="text-uppercase" routerLink = "/customer-service">CUSTOMER SERVICE
								</a>
							</li>
							<!-- <li><a  class="text-uppercase" routerLink = "/about-us">COMPANY
								</a>
							</li> -->
							<li><a  class="text-uppercase" routerLink = "/privacy-policy"> Privacy Policy</a></li>
						</ul>
					</div>
				</div>
			</div>
			<div class="mb-3 mb-md-0 col-md-4">
				<ul class="sNet nav">
					<li>
						<a class="waves-effect waves-dark facebook" href="https://www.facebook.com/Fino-Porcelain-105707564811479" target="_blank"><i class="fa fa-facebook"></i></a>
					</li>
					<li>
						<a class="waves-effect waves-dark twitter" href="https://twitter.com/FinoPorcelain" target="_blank"><i class="fa fa-twitter"></i></a>
					</li>
					<li>
						<a class="waves-effect waves-dark instagram" href="https://www.instagram.com/finoporcelain/" target="_blank"><i class="fa fa-instagram"></i></a>
					</li>
				</ul>
			</div>
		</div>
	</div>
</footer>