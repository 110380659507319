import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  tinyUrl= 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/finowebsite';
  constructor() { }

  ngOnInit(): void {
  }

}
