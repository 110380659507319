import { Component } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { UserService } from './shared/user.service'
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
declare let $: any;
declare let jQuery: any;
import { Meta, Title } from '@angular/platform-browser';
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	//   title = 'fino';
	getHeader: boolean = false

	constructor(private UserService: UserService, private router: Router, public title: Title, public meta: Meta) {

		this.ngAfterViewInit()
	}
	ngOnInit() {

		// if(this.router.url == '/'){
		// 	//console.log('ye in home');
			
		// 	$('header').removeClass('stHeader');
		// }else{
		// 	$('header').addClass('stHeader');
		// }
		//   //console.log(this.router.url);

		// this.getMessage()

		setTimeout(() => {
			this.getHeader = true;
		}, 300);
		setTimeout(() => {
			if(window.location.pathname === '/'){
				
					// console.log(window.location.pathname, 'ancd');
					
					$('header').removeClass('stHeader');
				}else{
					// console.log('in else p[art');
					
					$('header').addClass('stHeader');
				}
		}, 370);


	}
	ngAfterViewInit() {
		

		setTimeout(() => {

			window.onscroll = function () { myFunction() };
			$(document).ready(function () {
				let pathname = window.location.pathname;
				var header: any = document.getElementById("igm-body");
				// //console.log(header)
				if (header) {

					if (pathname.indexOf("/") != -1) {
						header.classList.add("noSticky");
						$('#headComp').removeClass('sticky')
					}

					
					 else {
						header.classList.remove("noSticky");
						$('#headComp').removeClass('sticky')

					}
				}


			});
			var header = document.getElementById("headComp");
			if (header) {

				var sticky: any = header.offsetTop;
			}

			function myFunction() {
				if (window.pageYOffset > sticky) {
					// let x = header.classList.findIndex(f => f == 'sticky' )
					if(window.location.pathname.includes('/porcelain/') || window.location.pathname.includes('/product-detail/')){
						// console.log('yes im in this oath');
						header.classList.remove("sticky");
						$('header.stHeader').addClass("position-absolute");
						$('.sectionJ').addClass("sRelative");
						// header.classList.remove("stHeader");	

					}else{
						header.classList.add("sticky");
						if(window.location.pathname == '/'){
							header.classList.remove("stHeader");
							$('#headComp').removeClass("position-absolute");
						}else{

						header.classList.add("stHeader");
						}
						// $('header.stHeader').removeClass("position-relative");
						$('.sectionJ').removeClass("sRelative");	
					}
					$('.slideHP').addClass('stick');
					$('.filterbtn').removeClass('filterSearch');
				} else {
					header.classList.remove("sticky");
					$('.slideHP').removeClass('stick');
					$('.filterbtn').addClass('filterSearch');
				}
			}
		}, 650);
	}
	changeOfRoutes(event) {
		window.scroll(0, 0)
		if(this.router.url == '/'){
			//console.log('ye in home');
			
			$('header').removeClass('stHeader');
			$('#headComp').removeClass("position-relative");
		}else{
			$('header').addClass('stHeader');
		}
		if(this.router.url.includes('contact-us')){
			this.title.setTitle('Contact Us |  Fino Porcelain')
		}else if(this.router.url.includes('location')){
			this.title.setTitle('Explore and Browse Porcelain Slabs at Different Locations | Fino Porcelain')
		}else if(this.router.url.includes('/customer-service')){
			this.title.setTitle('Proactive Customer Service | Fino Porcelain')
		}else if(this.router.url.includes('/porcelain')){
			this.title.setTitle('Browse Through an Extensive Range of Porcelain Products | Fino Porcelain')
		}else if(this.router.url.includes('/privacy-policy')){
			this.title.setTitle('Privacy Policy | Fino Porcelain')
		}else if(this.router.url.includes('/disclaimer')){
			this.title.setTitle('Disclaimer |  Fino Porcelain')
		}else if(this.router.url.includes('/faq')){
			this.title.setTitle('FAQs | Frequently Asked Questions About Porcelain Products | Fino Porcelain')
		}else if(this.router.url.includes('/book-appointment')){
			this.title.setTitle('Book an Appointment Online for Warehouse Visit |  Fino Porcelain')
		}else if(this.router.url.includes('/projects')){
			this.title.setTitle('High-Quality Quartz Products for Different Projects |  Fino Porcelain')
		}else if(this.router.url.includes('/architect')){
			this.title.setTitle('Durable Porcelain Slabs for Custom Projects for Architects | Fino Porcelain')
		}else if(this.router.url.includes('/carrer')){
			this.title.setTitle('Careers & Job Opportunities | Fino Porcelain')
		}else if(this.router.url.includes('/designer')){
			this.title.setTitle('Best Quality Porcelain Slab Products for Designers | Fino Porcelain')
		}else if(this.router.url.includes('/experience')){
			this.title.setTitle('Experience | Fino Porcelain')
		}else if(this.router.url.includes('/news')){
			this.title.setTitle('News and Events | Fino Porcelain')
		}else if(this.router.url.includes('/ourstory')){
			this.title.setTitle('Our Story | About Us | Fino Porcelain')
		}else if(this.router.url.includes('/professional')){
			this.title.setTitle('Exclusive Range of Porcelain Products for Different Professionals | Fino Porcelain')
		}else if(this.router.url.includes('/fabricators')){
			this.title.setTitle('Wide Range of Choice of Porcelain Slabs for Fabricators | Fino Porcelain')
		}else if(this.router.url.includes('/kitchen-studios')){
			this.title.setTitle('Renovate Your Kitchen and Bath Studios With Porcelain Slabs | Fino Porcelain')
		}else if(this.router.url.includes('/company')){
			this.title.setTitle('Company | Fino Porcelain')
		}
		else if(this.router.url.includes('/commercial')){
			this.title.setTitle('Premium Porcelain Products for Commercial & Residential Projects | Fino Porcelain | Fino Porcelain')
		}
		else if(this.router.url.includes('/residential')){
			this.title.setTitle('Premium Porcelain Products for Commercial & Residential Projects | Fino Porcelain | Fino Porcelain')
		}
		// else if(this.router.url.includes('/inspiration')){
		// 	this.title.setTitle('Premium Porcelain Products for Commercial & Residential Projects | Fino Porcelain')
		// }
		else{
			this.title.setTitle('Leading Provider of Durable & Beautiful Porcelain Slabs | Fino Porcelain')
		}
		console.log('acbd', $('#colspNav'));
		
		$('#colspNav').removeClass('show')

	}
}
