import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
	{ path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
	{ path: 'location', loadChildren: () => import('./location/location.module').then(m => m.LocationModule) },
	{ path: 'porcelain', loadChildren: () => import('./product-list/product-list.module').then(m => m.ProductListModule) },
	{ path: 'porcelain1/:id', loadChildren: () => import('./product-details1/product-details1.module').then(m => m.ProductDetails1Module) },
	{ path: 'about-us', loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule) },
	{ path: 'privacy-policy', loadChildren: () => import('./privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule) },
	{ path: 'contact-us', loadChildren: () => import('./contact-us/contact-us.module').then(m => m.ContactUsModule) },
	{ path: 'disclaimer', loadChildren: () => import('./disclaimer/disclaimer.module').then(m => m.DisclaimerModule) },
	{ path: 'faq', loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule) },
	{ path: 'customer-service', loadChildren: () => import('./customer-service/customer-service.module').then(m => m.CustomerServiceModule) },
	{ path: 'architect', loadChildren: () => import('./architect/architect.module').then(m => m.ArchitectModule) },
	{ path: 'career', loadChildren: () => import('./carrer/carrer.module').then(m => m.CarrerModule) },
	{ path: 'designer', loadChildren: () => import('./designer/designer.module').then(m => m.DesignerModule) },
	{ path: 'experience', loadChildren: () => import('./experience/experience.module').then(m => m.ExperienceModule) },
	{ path: 'news', loadChildren: () => import('./news/news.module').then(m => m.NewsModule) },
	{ path: 'ourstory', loadChildren: () => import('./ourstory/ourstory.module').then(m => m.OurstoryModule) },
	{ path: 'professional', loadChildren: () => import('./professional/professional.module').then(m => m.ProfessionalModule) },
	{ path: 'fabricators', loadChildren: () => import('./fabricators/fabricators.module').then(m => m.FabricatorsModule) },
	{ path: 'kitchen-studios', loadChildren: () => import('./kitchen-studios/kitchen-studios.module').then(m => m.KitchenStudiosModule) },
	{ path: 'inspiration', loadChildren: () => import('./inspiration/inspiration.module').then(m => m.InspirationModule) },
	{ path: 'book-appointment', loadChildren: () => import('./book-appointment/book-appointment.module').then(m => m.BookAppointmentModule) },
	{ path: 'not-found', loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)},
	{ path: 'company', loadChildren: () => import('./company/company.module').then(m => m.CompanyModule)},
	{ path: 'commercial', loadChildren: () => import('./commercial/commercial.module').then(m => m.CommercialModule)},
	{ path: 'residential', loadChildren: () => import('./residential/residential.module').then(m => m.ResidentialModule)},
	{ path: 'porcelain/:id', loadChildren: () => import('./product-detail/product-detail.module').then(m => m.ProductDetailModule)},

	{ path: '**', redirectTo: 'not-found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
