export const environment = {
	production: true
};
export interface Environment {
	endPoint: string;
	imageLink: string;
	websiteLink: string;
	tinyUrl: string;
	ctinyUrl: string;
	cosmoswebsite: string;

}

export const PROD: Environment = {
	endPoint: 'https://surfaces.cosmosgranite.com/api/',
	// endPoint:'https://staging.cosmosgranite.com/api/',
	imageLink: 'https://staging.cosmosgranite.com/',
	websiteLink: 'https:/  /staging.finoporcelain.com',
	tinyUrl: 'https://cosmosgranite.nyc3.digitaloceanspaces.com/cosmossurfaces/images',
	ctinyUrl: 'https://cosmosgranite.nyc3.cdn.digitaloceanspaces.com/finowebsite',
	cosmoswebsite : 'https://cosmosgranite.com/assets/maps/'

}

export const environment1: Environment = PROD;
